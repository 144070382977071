.single-hero-slide {
    background-color: #004caa;
    padding-top: 200px;
    padding-bottom: 220px;
    font-family: "Josefin Sans", sans-serif;
}

.hero-slide-left {
    padding-right: 60px;
}

.hero-slide-left h2 {
    font-size: 36px;
}

.hero-slide-left h1 {
    font-size: 62px;
    line-height: 75px;
    margin-bottom: 20px;
}

.hero-slide-left p {
    color: #ddd!important;
    font-family: 'Roboto Slab', serif;;
}

.hero-slide-left button {
    margin-top: 40px;
    font-weight: 700;
    outline: none!important;
    cursor: pointer;
    font-family: 'Roboto Slab', serif;
}

.heroTeeth {
    max-width: 100%;
    background-image: url('../../../Images/heroTeethBg.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.theme-btn {
    display: inline-block;
    border: 1px solid #fc5185;
    height: 61px;
    text-align: center;
    line-height: 55px!important;
    padding: 0 55px;
    background: #fc5185;
    text-transform: capitalize;
    position: relative;
    z-index: 2;
    border-radius: 3px;
    color: #fff;
    transition: .4s;
    font-weight: 600;
}

.btn-fill {
    margin-right: 20px;
}

.btn-fill:hover {
    background: #fff;
    color: #000!important;
    border-color: #fff;
}

.bth-blank {
    border-color: #fff;
    background: transparent;
}

.bth-blank:hover {
    border-color: #fc5185;
    color: #fff!important;
    background: #fc5185;
}

.animate-img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 22;
}

.animate-img .aimg1 {
    left: 210px;
    position: relative;
    animation: movebounce1 5s ease-in-out infinite;
    animation-delay: 100ms;
    animation-direction: alternate;
    bottom: 15px;
}

@keyframes movebounce1 {
    0% {
        transform: translate(0px, 0px);
    }
    50% {
        transform: translate(40px, -60px); /* Moves to the right and up */
    }
    100% {
        transform: translate(40px, 40px);
    }
}


.animate-img .aimg2 {
    max-width: 40%;
    position: relative;
    left: 385px;
    top: 100px;
    animation-delay: 200ms;
    animation: movebounce 2.5s ease-in-out infinite;
    animation-direction: alternate;
}

@keyframes movebounce {
    0% {
        transform: translate(0px, 0px);
    }
    50% {
        transform: translate(90px, 40px); /* Moves to the right and up */
    }
    100% {
        transform: translate(0px, 0px);
    }
}


/* for mobile responsive */
@media (max-width: 1024px) {
    /* banner */
    .animate-img .aimg1 {
        left: 209px;
        top: 133px;
    }

    .animate-img .aimg2 {
        left: 355px;
        top: 87px;
    }
}

@media (max-width: 992px) {
    /* banner */
    .animate-img .aimg1 {
        left: -25px;
        top: 381px;
    }

    .animate-img .aimg2 {
        max-width: 60%;
        left: -37px;
        top: 637px;
    }

    .hero-slide-left {
        padding: 0;
    }
}

@media (max-width: 769px) {
    /* banner */
    .animate-img .aimg1 {
        left: -25px;
        top: 381px;;
    }

    .animate-img .aimg2 {
        left: -37px;
        top: 629px;
    }
}

@media (max-width: 576px) {
    .hero-slide-right {
        margin-top: 70px;
    .animate-img .aimg2 {
        max-width: 60%;
        left: -42px;
        top: 583px;
    }
    }
}

@media (max-width: 512px) {
    /* banner */
    .animate-img .aimg1 {
        left: -92px;
        top: 605px;
    }

    .animate-img .aimg2 {
        max-width: 60%;
        left: -42px;
        top: 563px;
    }
}

@media (max-width: 461px) {
    /* banner */
    .animate-img .aimg1 {
        left: -91px;
        top: 682px;
    }

    .animate-img .aimg2 {
        left: -44px;
        top: 635px;
    }
}

@media (max-width: 445px) {
    /* banner */
    .animate-img .aimg1 {
        left: -88px;
        top: 799px;
    }

    .animate-img .aimg2 {
        left: -34px;
        top: 621px;
    }

    .banner-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 426px) {
    .single-hero-slide {
        padding-top: 80px;
        padding-bottom: 40px;
    }

    .hero-slide-left button {
        margin: 1rem 0;
    }

    .animate-img .aimg1 {
        top: 605px;
        left: -85px;
    }

    .animate-img .aimg2 {
        left: -34px;
        top: 487px;
    }
}

@media (max-width: 376px) {
    .single-hero-slide {
        padding-bottom: 85px;
    }

    .animate-img .aimg1 {
        top: 597px;
        left: -72px;
    }

    .animate-img .aimg2 {
        left: -34px;
        top: 512px;
    }
}

@media (max-width: 321px) {
    .container-head {
        max-width: 90%;
    }

    .animate-img .aimg1 {
        max-width: 65%;
        top: 575px;
        left: -61px;
    }

    .animate-img .aimg2 {
        max-width: 65%;
        left: -30px;
        top: 579px;
    }

}
