.post-title {
  font-size: 2em;
  margin-top: 150px;
  margin-bottom: 90px;
}

.post-img {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin-bottom: 20px;
}

.post-content {
  font-size: 1.2em;
  text-align: justify;
  padding: 0 15%;
  font-family: Arial, sans-serif;
}

.navigation-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;
}

.navigation-button:hover {
  background-color: #0056b3;
}