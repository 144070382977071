.blog-list-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.blog-list-title {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 50px;
}

.blog-list {
  list-style-type: none;
  padding: 0;
}

.blog-list-item {
  margin-bottom: 20px;
}

.post-link {
  text-decoration: none;
  color: #333;
}
